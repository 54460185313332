






















































































































import { Component, Vue } from 'vue-property-decorator'
import { generateTasksColorsRule } from '@/api/v3/DataGenerator'
import { Getter } from 'vuex-class'
import { uiStore, tasksStore } from '@/store'
import type RuleCard from './RuleCard/index.vue'

@Component({
  name: 'TasksColorsRules',
  components: {
    draggable: () => import('vuedraggable'),
    IconQuestionCircle: () => import('@/components/UI/icons/IconQuestionCircle.vue'),
    RuleCard: () => import('./RuleCard/index.vue'),
  },
})
export default class TasksColorsRules extends Vue {
  isLoading = true // true by default (see created hook)
  isSaving = false
  newRule: TADA.TasksColorsRule | null = null // holder for yet unsaved new rule
  isEditing = false

  @Getter canManageTasksColorsRules!: boolean

  /**
   * Sort existing rules by priority.
   * Push newRule to the start if it exists.
   */
  get rules (): TADA.TasksColorsRule[] {
    const orderedExistingRules = [...tasksStore.state.colorsRules]
      .sort((r1, r2) => r2.priority - r1.priority)
    return this.newRule
      ? [this.newRule, ...orderedExistingRules]
      : orderedExistingRules
  }

  /**
   * React to drag finish basically.
   */
  set rules (newOrderedRules) {
    if (!newOrderedRules) return // just in case
    this.isSaving = true

    const rules: Partial<TADA.TasksColorsRule>[] = []
    newOrderedRules.forEach((r, i) => {
      // why all this struggle?
      // because server does not guarantee that rule priorities are unique
      // so calculate unique ones based on what user actually sees
      const newPriority = newOrderedRules.length - i

      // yet still use priority from the rule itself to detect old value
      const oldPriority = this.rules.find(oldR => oldR.uid === r.uid)?.priority

      // add the rule to future server request payload
      // even if it didn't move up-down the list, but its priority has changed
      if (oldPriority === newPriority) return
      const updatedRule = {
        uid: r.uid,
        priority: newPriority,
      }
      rules.push(updatedRule)
    })

    // computted setter should not be async, so sync it!
    rules.length > 0 && tasksStore.actions.updateColorsRules(rules).then(() => {
      this.isSaving = false
    })
  }

  created () {
    // reload on every modal open
    // no socket event to stay updated on rules
    this.loadTasksColorsRules()
  }

  onEditEnd (ruleUid: string): void {
    this.isEditing = false

    // remove newRule if it was edited - it will be either saved or discarded
    this.newRule?.uid === ruleUid && (this.newRule = null)
  }

  onEditStart (): void {
    this.isEditing = true
  }

  async addRule (): Promise<void> {
    // calculate priority to put the rule on top of the list
    let priority
    if (tasksStore.state.colorsRules.length > 0) {
      const existingPriorities = tasksStore.state.colorsRules.map(r => r.priority)
      const currentMaxPriority = Math.max(...existingPriorities)
      priority = currentMaxPriority + 1
    } else {
      priority = 1
    }

    this.newRule = generateTasksColorsRule({ priority })
    const refReference = `ruleCard-${this.newRule.uid}`

    // wait for new rule card and menu inside to be mounted
    this.$nextTick(() => {
      const ruleCard: RuleCard[] = (this.$refs[refReference] as RuleCard[])
      // when used with v-for, $refs[refName] returns an array
      // open menu to start editing rule right away
      ruleCard && ruleCard[0] && ruleCard[0].startEdit()
    })
  }

  async loadTasksColorsRules (): Promise<void> {
    // TODO: backend - generate websocket event to let user know that rules were updated
    this.isLoading = true
    await tasksStore.actions.setupColorsRules()
    this.isLoading = false
  }

  close (): void {
    uiStore.actions.hideModal()
  }
}
